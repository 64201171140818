import { Spinner } from "@blueprintjs/core";
import React, { useEffect, useState } from "react";
import styles from './pulltorefresh.module.css';
import SIcon from "./sicon";
import { IconNames } from "@blueprintjs/icons";

const REFRESH_THRESHOLD = -200;

type Props = {
    children?: React.ReactNode;
    onRefresh?: () => void;
    threshold?: number;
}

export default function PullToRefresh(
    {
        children,
        onRefresh,
        threshold = REFRESH_THRESHOLD,
    }: Props,
): React.ReactNode {
    const [isTouching, setIsTouching] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [scrollY, setScrollY] = useState<number>(NaN);

    useEffect(
        () => {
            if (!globalThis.window) {
                return;
            }

            const onTouchStart = () => {
                setIsTouching(true);
            }

            const onTouchEnd = () => {
                setIsTouching(false);

                if (onRefresh != null && window.scrollY <= threshold) {
                    setIsRefreshing(true);
                    onRefresh();
                }
            }

            window.addEventListener('touchstart', onTouchStart);
            window.addEventListener('touchend', onTouchEnd);

            return () => {
                window.removeEventListener('touchstart', onTouchStart);
                window.removeEventListener('touchend', onTouchEnd);
            }
        },
        [onRefresh, setIsTouching, threshold],
    );

    useEffect(
        () => {
            const onScroll = () => {
                if (!globalThis.window) {
                    return;
                }

                setScrollY(window.scrollY);
            }

            window.addEventListener('touchmove', onScroll);

            return () => {
                window.removeEventListener('touchmove', onScroll);
            }
        },
        [setScrollY],
    );

    let scrollPercent = 0;
    if (isTouching && !isRefreshing) {
        scrollPercent = scrollY / threshold;

        if (scrollPercent < 0) {
            scrollPercent = 0;
        } else if (scrollPercent > 1) {
            scrollPercent = 1;
        }
    } else if (isRefreshing) {
        scrollPercent = 1;
    }

    const iconClasses = [styles.icon];
    if (scrollPercent >= 1) {
        iconClasses.push(styles.iconRefresh)
    }

    let icon;
    const indicatorClasses = [styles.indicator];
    if (isRefreshing) {
        icon = <Spinner />;
        indicatorClasses.push(styles.indicatorLoading);
    } else {
        icon = <SIcon
            icon={IconNames.ArrowDown}
            className={iconClasses.join(' ')}
        />;
    }

    return <React.Fragment>
        <div className={indicatorClasses.join(' ')} style={{ opacity: scrollPercent }}>
            {icon}
        </div>
        {children}
    </React.Fragment>;
}
